<script setup lang="ts">
import { VAppBar } from "vuetify/components/VAppBar";
import { mdiMenu, mdiAccountCircle } from "@mdi/js";
import { useAuthStore } from "@/stores/auth";

const emit = defineEmits<{
  (e: "toggle:sidebar"): void;
}>();

const { isLoggedIn } = useAuthStorage();
const { theme } = useThemeStorage();
const authStore = useAuthStore();
const { success } = useSnack();
const router = useRouter();

const isProfileMenuOpen = ref(false);

const logoutHandler = () => {
  isProfileMenuOpen.value = false;
  authStore.logout();
  success("Logged out!");
  router.push({ name: "index" });
};
</script>

<template>
  <VAppBar elevation="2">
    <template #prepend>
      <MBtn
        v-if="isLoggedIn"
        type="button"
        icon
        @click="emit('toggle:sidebar')"
      >
        <MIcon :icon="mdiMenu" />
      </MBtn>
      <BtnLink
        :route="{ name: 'index' }"
        color="default"
        :active="false"
        height="3rem"
      >
        <img
          :src="`/images/logo-en-${theme}.svg`"
          class="h-[2.2rem] object-scale-down"
        />
        <span class="text-2xl text-gray"> Admin </span>
      </BtnLink>
    </template>
    <template #append>
      <div class="flex items-center gap-x-2">
        <MMenu
          v-if="isLoggedIn"
          v-model="isProfileMenuOpen"
        >
          <template #activator="{ props }">
            <MBtn
              icon
              v-bind="props"
              color="default"
              type="button"
            >
              <MIcon :icon="mdiAccountCircle" />
            </MBtn>
          </template>
          <MCard>
            <MList minWidth="12rem">
              <MListItem>
                <template #title>
                  <p>Hello, {{ authStore.userDetails?.fullName }}</p>
                </template>
                <template #subtitle>
                  <p>
                    {{ authStore.userDetails?.email }}
                  </p>
                </template>
              </MListItem>
              <MListItem @click="logoutHandler"> Logout </MListItem>
            </MList>
          </MCard>
        </MMenu>
      </div>
    </template>
  </VAppBar>
</template>
